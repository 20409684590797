import React from "react";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <footer className="bg-gray-100 border-t border-gray-200 py-10">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="grid sm:grid-cols-12 gap-8 py-8 border-t border-gray-300">
            {/* 連結部分 */}
            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <h6 className="text-blue-900 text-xl font-bold mb-4">連結</h6>
              <ul className="text-md space-y-2">
                <li>
                  <HashLink
                    to="/Home"
                    className="text-blue-900 hover:text-gray-900 transition duration-200 ease-in-out"
                  >
                    首頁
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/Kindergarten#kindergarten"
                    className="text-blue-900 hover:text-gray-900 transition duration-200 ease-in-out"
                  >
                    幼兒園
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/EnglishCourse#englishcourse"
                    className="text-blue-900 hover:text-gray-900 transition duration-200 ease-in-out"
                  >
                    兒童美語
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/Tutor#tutor"
                    className="text-blue-900 hover:text-gray-900 transition duration-200 ease-in-out"
                  >
                    國小課輔班
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/contact#contact"
                    className="text-blue-900 hover:text-gray-900 transition duration-200 ease-in-out"
                  >
                    聯絡我們
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 關注我們的Facebook */}
            <div className="col-span-12 text-center md:col-span-4 lg:col-span-3">
              <h6 className="text-blue-900 text-xl font-bold mb-4">
                關注我們的Facebook
              </h6>
              <div className="flex justify-center space-x-6">
                <div>
                  <a
                    href="https://www.facebook.com/ewx8906b/?locale=zh_TW"
                    className="text-blue-900 hover:text-gray-500 transition duration-200 ease-in-out"
                  >
                    <svg
                      className="w-8 h-8 fill-current mx-auto"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                    </svg>
                    <h1 className="mt-2">心象分校(新店區)</h1>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/p/奧斯汀美語文化分校-61555886510244"
                    className="text-blue-900 hover:text-gray-500 transition duration-200 ease-in-out"
                  >
                    <svg
                      className="w-8 h-8 fill-current mx-auto"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                    </svg>
                    <h1 className="mt-2">文化分校(平鎮區)</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
