import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import ContactCt from "./pages/ContactCt";
import Kindergarten from "./pages/Kindergarten";
import EnglishCourse from "./pages/EnglishCourse";
import Tutor from "./pages/Tutor";
// import DemoProduct from "./pages/DemoProduct";

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./pages/HomePage";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  useDocTitle("奧斯汀美語 - 平鎮文化分校");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path={encodeURI("/")} element={<Home />} />
            <Route path={encodeURI("/Home")} element={<HomePage />} />
            <Route
              path={encodeURI("/奧斯汀美語-桃園安親班")}
              element={<Kindergarten />}
            />
            <Route
              path={encodeURI("/奧斯汀美語-兒童美語")}
              element={<EnglishCourse />}
            />
            <Route
              path={encodeURI("/奧斯汀美語-暑假-國小課輔班")}
              element={<Tutor />}
            />
            <Route
              path={encodeURI("/奧斯汀美語-文化分校-地址")}
              element={<Contact />}
            />
            <Route
              path={encodeURI("/奧斯汀美語-心象文理補習班-地址")}
              element={<ContactCt />}
            />
            {/* <Route path="/get-demo" element={<DemoProduct />} /> */}
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
