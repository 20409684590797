import React from "react";
//import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import heroImg from "../images/heroImg.jpg";

const Hero = () => {
  return (
    <>
      <div className="hero" id="hero">
        <NavBar />
        <div
          className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
          data-aos="zoom-in"
        >
          <div
            className="flex flex-col lg:flex-col py-8 justify-between items-center text-center lg:text-left"
          >
            <div
              className="lg:w-1/2 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1 className="mb-5 text-3xl md:text-5xl font-bold text-blue-900">
                奧斯汀美語
                <br />
                專注於兒童美語教育的補習班
              </h1>
              <p className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                提供互動式課程，結合遊戲與創意活動，讓孩子在快樂中學習英文。
                <br />
                我們的目標是培養孩子的語言能力與自信心！奧斯汀美語這麼好，
                別讓孩子錯過了！
              </p>
              
            </div>
            <div
              className="flex justify-center lg:justify-end w-full lg:w-1/2"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <img
                alt="Hero"
                className="rounded-lg duration-1000 w-full h-auto object-contain"
                src={heroImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
