import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import Eg1 from "../images/eg/eg1.png";
import Eg2 from "../images/eg/eg2.png";
import Eg3 from "../images/eg/eg3.jpg";
import Eg4 from "../images/eg/eg4.jpg";
import Eg5 from "../images/eg/eg5.jpg";
import Eg6 from "../images/eg/eg6.png";
import Eg7 from "../images/eg/eg7.png";

const Englishcourse = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="tutor"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24"
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <div className="flex flex-col">
            <h2 className="text-4xl mb-3 ">
              3 ~ 12歲 建立孩子正確學習語言的方法與態度是踏出成功的第一步!
            </h2>
            <p className="text-gray-600 ml-4 text-lg ">
              以故事主軸教學模式、訓練口說技巧、E化翻轉學習、唱歌＋美勞學英文，以生動、活潑、零壓力教學法來激發兒童學習興趣。多樣化教學，讓孩子聽、說、讀、寫均衡發展。修完6級課程，已達國中畢業生英文水準。
              <br />
              目前台灣國中升高中會考-英文部分，分為閱讀測驗與聽力兩大部分，大部分試題皆以閱讀測驗為主，從小培養孩子大量閱讀英文故事與訓練英文口說、聽力能力是刻不容緩之事，奧斯汀美語-新店心象分校洞見觀瞻，在課程中融入跨領域學習，培養孩子從小將英語閱讀融入生活學習中，以俾順利銜接國中課程，讓孩子聽、說、讀、寫進步滿點。
            </p>
            <br />

            <div>
              <img
                src={Eg2}
                alt="英語課程教材"
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <img
              src={Eg1}
              alt="孩子學習英文的情景"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>

          <div>
            <img
              src={Eg3}
              alt="學生互動照片1"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div>
            <img
              src={Eg4}
              alt="學生互動照片2"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div>
            <img
              src={Eg5}
              alt="學生上課照片1"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div>
            <img
              src={Eg6}
              alt="學生上課照片2"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div>
            <img
              src={Eg7}
              alt="學生上課照片3"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="section4">
        <div className="flex justify-center space-x-4" data-aos="fade-up">
          <div className="w-1/2">
            <iframe
              className="w-full h-64 rounded-lg"
              src="https://www.youtube.com/embed/_bAJtgCznoM"
              title="YouTube video player"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="w-1/2">
            <iframe
              className="w-full h-64 rounded-lg"
              src="https://www.youtube.com/embed/u-YkRkHLV48"
              title="YouTube video player"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Englishcourse;
