import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import ContactCt from "./pages/ContactCt";
import Kindergarten from "./pages/Kindergarten";
import EnglishCourse from "./pages/EnglishCourse";
import Tutor from "./pages/Tutor";
// import DemoProduct from "./pages/DemoProduct";

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  useDocTitle("奧斯汀美語");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/kindergarten" element={<Kindergarten />} />
            <Route path="/englishcourse" element={<EnglishCourse />} />
            <Route path="/tutor" element={<Tutor />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contactCt" element={<ContactCt />} />
            {/* <Route path="/get-demo" element={<DemoProduct />} /> */}
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
