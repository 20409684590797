//import React, { useState } from "react";
import Footer from "../components/Footer.js";
import NavBar from "../components/Navbar/NavBar.js";
import Ph1 from "../images/tutor/1.jpg";
import Ph2 from "../images/tutor/2.jpg";
import Ph3 from "../images/tutor/3.jpg";
const Tutor = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="tutor"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 "
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <div className="flex flex-col ">
            <h2 className="text-4xl mb-3">
              協助孩子解決課業上的困難，培養真正的思考能力！
            </h2>

            <p className="text-gray-600 ml-4 text-lg">
              在面對國小課業時，孩子們經常在數學題目上卡關，或是國語寫錯字，但卻不知道如何改進？我們致力於幫助孩子解決這些難題，不僅僅是死背知識，更重要的是教會他們如何運用思考。我們針對孩子的學習需求，加強課後輔導和課前預習，細心陪伴孩子，讓他們在成長的過程中逐步提升學習能力。通過提升專注力，幫助孩子建立正確的人生觀，讓他們的生活與課業一起穩步進步。
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto p-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <image
              src={Ph1}
              alt="Image 1"
              class="w-5/6 h-auto rounded shadow-md"
            />
          </div>
          <div>
            <image
              src={Ph2}
              alt="Image 2"
              class="max-w-1xl h-auto rounded shadow-md"
            />
          </div>
          <div>
            <image
              src={Ph3}
              alt="Image 3"
              class="max-w-3xl h-auto rounded shadow-md"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center flex-wrap">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/b9-uREnnyAw?si=W_u_366pXTU2l4ou"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/mJ05Rkxsw-E?si=jEurSEAM8zGGLBPc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>

      <Footer />
    </>
  );
};

export default Tutor;
