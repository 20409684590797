import React, { useState, useEffect } from "react";
import NavLinks from "../Navbar/NavLinks";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/logo.png";

const NavBar = () => {
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const scrollHandler = () => {
      setTop(window.pageYOffset <= 10);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <nav
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out ${
        !top ? "bg-white shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        <HashLink smooth to="/#hero">
          <img src={logo} alt="MLD Logo" className="h-12 w-auto" />
        </HashLink>

        <div className="flex items-center">
          <button
            className="lg:hidden p-2 rounded-lg text-blue-900 focus:outline-none"
            onClick={handleClick}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z"
                />
              )}
            </svg>
          </button>
          <div className="hidden lg:flex space-x-6">
            <NavLinks />
          </div>
        </div>

        <div
          className={` fixed left-0 top-16 w-full bg-white shadow-xl transform transition-transform duration-300 ${
            isOpen ? "translate-y-0 block" : "-translate-y-full hidden"
          }`}
        >
          <div className="flex flex-col items-center space-y-6 py-6">
            <NavLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
