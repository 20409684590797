import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900 text-lg"
        smooth
        to="/"
      >
        首頁
      </HashLink>

      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900 text-lg"
        to="/Kindergarten"
      >
        幼兒園
      </HashLink>

      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900 text-lg"
        smooth
        to="/EnglishCourse"
      >
        兒童美語
      </HashLink>

      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900 text-lg"
        to="/Tutor"
      >
        國小課輔班
      </HashLink>

      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900 text-lg"
        to="/contact"
      >
        {"心象分校(新北市新店區)"}
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900 text-lg"
        to="/contactCt"
      >
        {"文化分校(桃園市平鎮區)"}
      </HashLink>
    </>
  );
};

export default NavLinks;
