import img from "../images/textbook.jpg";

const intro = () => {
  return (
    <>
      <div classname="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <div
          classname="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div classname="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" classname="rounded-lg float-right" src={img} />
          </div>

          <div
            classname="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 classname="text-2xl  text-blue-900 font-bold">
              身處在競爭激烈的世代，語言是孩子面對未來必備的利器，奧斯汀美語-新店心象分校提供優質美語學習環境，從孩子角度出發，讓孩子自然而然開口說英文，擺脫傳統填鴨式教學，讓孩子快樂學習，幫助孩子開啟英語學習的大門，進而與國際接軌。
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default intro;
